import React from "react";
import { Typography } from "@novaescola/eva-react-v2";
import algoliasearch from "algoliasearch/lite";
import {
  Configure,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-hooks-web";
import { isMobile } from "react-device-detect";

import Layout from "../components/Layout";
import Seo from "../components/general/Seo";
import BreadCrumb from "../components/general/Breadcrumb";

import CustomCurrentRefinements from "../components/busca/CustomCurrentRefinements";
import {
  AnoCustomRefinementList,
  ComponenteCustomRefinementList,
  NaoRegionalizadoCustomRefinementList,
  RegiaoCustomRefinementList,
} from "../components/busca/refinementList";
import Results from "../components/busca/Results";
import CustomPagination from "../components/busca/CustomPagination";

const INDEX_NAME = process.env.GATSBY_ALGOLIA_INDEX_NAME as string;
const HITS_PER_PAGE = 12;
const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID || "",
  process.env.GATSBY_ALGOLIA_API_KEY || ""
);

const CatalogoPage: React.FC = () => {
  return (
    <Layout heroClass="no-hero" headerClass="padding-header">
      <Seo
        title="Material didático | Livros gratuitos para professores e alunos"
        description="Encontre os materiais do professor e do aluno"
      />

      <main className="container busca eva-margin-top-4">
        <BreadCrumb
          items={[
            { text: "Material Educacional", url: "/" },
            { text: "Catálogo de Materiais" },
          ]}
        />
        <Typography variant="title" size={4} component="h1" className="eva-margin-bottom-3">
          Encontre os materiais do professor e do aluno
        </Typography>
    
        <Typography variant="title" size={5} component="h2" className="eva-margin-bottom-2">
          Busca e filtros
        </Typography>

        <InstantSearch
          searchClient={searchClient}
          indexName={INDEX_NAME}
        >
          <Configure hitsPerPage={HITS_PER_PAGE} />

          <div className="row">
            <aside className="col-sm-12 col-md-3">
              <div className="container-search-theme eva-margin-bottom-4">
                <div id="inputBusca">
                  <i className="fas fa-search eva-input-icon" />
                  <SearchBox placeholder="Busque materiais" />
                </div>

                {isMobile && <CustomCurrentRefinements />}

                <NaoRegionalizadoCustomRefinementList attribute="regionalizado" />

                <RegiaoCustomRefinementList
                  attribute="regiao"
                  sortBy={["name:asc"]}
                />

                <AnoCustomRefinementList
                  attribute="ano"
                  showMoreLimit={3}
                  sortBy={["name:asc"]}
                />

                <ComponenteCustomRefinementList
                  attribute="livros.componentes"
                  showMoreLimit={3}
                />
              </div>
            </aside>

            <section
              id="resultadosBusca"
              className="col-sm-12 col-md-9 no-padding"
            >
              <div className="row">
                <div className="col">
                  <Results />
                </div>
              </div>

              <div className="row d-flex jc-center eva-margin-top-4">
                <CustomPagination />
              </div>
            </section>
          </div>
        </InstantSearch>
      </main>
    </Layout>
  );
};

export default CatalogoPage;
